import Lottie from "lottie-react";
import animationData from "../assets/web-animation3.json";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GetInTouchSection = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  return (
    <section className="px-3 py-5" data-aos="fade-left">
      <div className="grid lg:grid-cols-2 items-center justify-items-center gap-5">
        <div className="order-1 lg:order-2 sm:order-2 flex flex-col justify-center md:items-start sm:items-center">
          <p className=" text-4xl maxMobile:text-2xl maxTablet:text-center font-bold md:mb-4 sm:mb-1 text-purple-900">
            {t("home.getTouchTitle")}
          </p>
          <hr className="md:w-[10%] sm:w-[30%] border-t-4 md:mb-8 sm:mb-4 border-purple-800" />
          <p className="mt-2 md:text-lg maxTablet:text-center text-gray-500 md:text-start mb-5 sm:text-center">
            {t("home.getTouchDesc")}
          </p>
          <Button color="inherit" size="large" variant="outlined">
            <Link to={"/contact"}>{t("home.bannerBtn")}</Link>
          </Button>
        </div>
        <div className="order-2 lg:order-1 sm:order-1">
          <div className="lg:p-20 sm:p-8">
            <Lottie animationData={animationData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouchSection;
