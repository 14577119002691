import baseRequest from "../../utils/baseUrl";
import { toast } from "react-toastify";
import { featureActions } from "../slices/featuresSlice";

export const getAllFeatures = () => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get("/features");
      dispatch(featureActions.setFeatures(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getFeaturesCount = () => {
  return async (dispatch, getState) => {
    try {
      const data = await baseRequest.get("/features/count", {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(featureActions.setFeaturesCount(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getFeatureById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get(`/features/${id}`);
      dispatch(featureActions.setFeature(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const createFeature = (feature) => {
  return async (dispatch, getState) => {
    try {
      dispatch(featureActions.setLoading());
      await baseRequest.post("/features", feature, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(featureActions.setIsFeatureCreated());
      setTimeout(() => dispatch(featureActions.clearIsFeatureCreated()), 2000);
      toast.success("Feature Created Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch(featureActions.clearLoading());
    }
  };
};

export const updateFeatureImage = (image, id) => {
  return async (dispatch, getState) => {
    try {
      await baseRequest.put(`/features/update-image/${id}`, image, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Image Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const updateFeature = (feature, id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.put(`/features/${id}`, feature, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(featureActions.setFeature(data));
      toast.success("Feature Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const deleteFeature = (id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.delete(`/features/${id}`, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(featureActions.deleteFeature(data.id));
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};
