import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const TechnologiesSection = ({ technology }) => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  return (
    <div className="mt-20 md:mb-10 sm:mb-5" data-aos="fade-in">
      <p className="text-center text-4xl maxMobile:text-2xl font-bold md:mb-4 sm:mb-1 text-purple-900">
        {t("home.technologyStack")}
      </p>
      <hr className="md:w-[5%] sm:w-[10%] m-auto border-t-4 mb-8 border-purple-800" />
      <Swiper
        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={6}
        loop={true}
        freeMode={true}
        grabCursor={true}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          250: {
            slidesPerView: 3,
          },
          639: {
            slidesPerView: 3,
          },
          865: {
            slidesPerView: 4,
          },
          1000: {
            slidesPerView: 5,
          },
          1500: {
            slidesPerView: 6,
          },
          1700: {
            slidesPerView: 7,
          },
        }}
        speed={3000}
        _freeModeNoMomentumRelease={false}
        // navigation
        // pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        autoplay={{
          delay: 1,
          disableOnInteraction: true,
        }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
      >
        {technology.technologies.map((item) => (
          <SwiperSlide key={item?._id}>
            <div className="flex justify-center">
              <img
                src={item?.image?.url}
                className="md:w-[150px] sm:!w-[100px]"
                alt=""
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TechnologiesSection;
