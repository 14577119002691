import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const lang = localStorage.getItem("lang");
const i18nextLng = localStorage.getItem("i18nextLng");
const THEME =
  lang === "en" || i18nextLng === "en"
    ? createTheme({
        typography: {
          fontFamily: `"Cairo", sans-serif`,
        },
      })
    : createTheme({
        typography: {
          // fontFamily: `"Almarai", sans-serif`,
          fontFamily: `'Tajawal', sans-serif`,
        },
      });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={THEME}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
