import { Box, Card, Container } from "@mui/material";
import React, { useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceById,
  updateService,
  updateServiceImage,
} from "../../../redux/services/servicesAPI";
import Loader from "../../../components/Loader";
import AdminUpdateCard from "../AdminUpdateCard";

const AdminServiceDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { service, isLoadingData } = useSelector((state) => state.services);
  // const service = services.services.filter((item) => item._id === id);

  const submitUpdateForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(
      updateService(
        {
          title_ar: formData.get("title_ar"),
          title_en: formData.get("title_en"),
          description_ar: formData.get("description_ar"),
          description_en: formData.get("description_en"),
        },
        id
      )
    );
  };

  const submitUpdateImageForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(updateServiceImage({ image: formData.get("image") }, id));
  };

  useEffect(() => {
    dispatch(getServiceById(id));
  }, [dispatch, id]);
  return (
    <Box sx={{ display: "flex", direction: "ltr" }}>
      <AdminSidebar />
      <Container>
        <Card sx={{ mt: 5, borderRadius: 5, p: 5 }}>
          {isLoadingData ? (
            <Loader />
          ) : (
            <AdminUpdateCard
              backLink={"services"}
              submitUpdateForm={submitUpdateForm}
              submitUpdateImageForm={submitUpdateImageForm}
              data={service}
            />
          )}
        </Card>
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default AdminServiceDetails;
