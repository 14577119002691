import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    isLoadingData: true,
    isServiceCreated: false,
    isLoadingCreate: false,
    isError: false,
    services: [],
    service: null,
    servicesCount: 0,
  },

  reducers: {
    setServices(state, action) {
      state.isLoadingData = false;
      state.services = action.payload;
    },
    setServicesCount(state, action) {
      state.isLoadingData = false;
      state.servicesCount = action.payload;
    },
    setLoading(state) {
      state.isLoadingCreate = true;
    },
    clearLoading(state) {
      state.isLoadingCreate = false;
    },
    setIsServiceCreated(state, action) {
      state.isServiceCreated = true;
      state.isLoadingCreate = false;
    },
    clearIsServiceCreated(state, action) {
      state.isServiceCreated = false;
    },
    setService(state, action) {
      state.isLoadingData = false;
      state.service = action.payload;
    },
    deleteService(state, action) {
      state.services = state.services.filter(
        (item) => item._id !== action.payload
      );
    },
  },
});

const serviceReducer = serviceSlice.reducer;
const serviceActions = serviceSlice.actions;

export { serviceReducer, serviceActions };
