import React, { useEffect } from "react";
import ContactForm from "../components/ContactForm";
import Lottie from "lottie-react";
import animationData from "../assets/web-animation7.json";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

const ContactScreen = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    Aos.init({ duration: 2500 });
  }, []);
  return (
    <div>
      <section className="px-3 py-5 lg:py-5" data-aos="fade-in">
        <div className="grid lg:grid-cols-2 items-center justify-items-center gap-5">
          <div className="order-2 lg:order-1 flex flex-col justify-center md:items-start sm:items-center">
            <p className="md:text-4xl sm:text-2xl capitalize font-bold text-purple-900 md:text-start sm:text-center">
              {t("contact.bannerTitle")}
            </p>
            <p className="mt-2 text-gray-500 md:text-start mb-5 sm:text-center">
              {t("contact.bannerDesc")}
            </p>
          </div>
          <div className="order-1 lg:order-2">
            <Lottie animationData={animationData} />
          </div>
        </div>
      </section>
      <ContactForm />
      <ToastContainer />
    </div>
  );
};

export default ContactScreen;
