import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostById } from "../redux/services/blogApi";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const BlogDetailsScreen = () => {
  const lang = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const { post } = useSelector((state) => state.blog);
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getPostById(id));
  }, [id, dispatch]);
  return (
    <div className="blog">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          mx: "auto",
          mt: 5,
        }}
      >
        <img
          className="rounded-3xl mb-5"
          alt={post?.title_en}
          src={post?.image?.url}
        />
        <Typography
          variant="h1"
          sx={{
            color: "purple",
            fontWeight: "500",
            fontSize: 48,
            my: 3,
          }}
        >
          {lang === "en" ? post?.title_en : post?.title_ar}
        </Typography>
        <div
          className="text-start"
          dangerouslySetInnerHTML={{
            __html: lang === "en" ? post?.description_en : post?.description_ar,
          }}
        ></div>
      </Box>
    </div>
  );
};

export default BlogDetailsScreen;
