import { createSlice } from "@reduxjs/toolkit";

const featureSlice = createSlice({
  name: "feature",
  initialState: {
    isLoadingData: true,
    isFeatureCreated: false,
    isLoadingCreate: false,
    isError: false,
    features: [],
    feature: null,
    featureCount: 0,
  },

  reducers: {
    setFeatures(state, action) {
      state.isLoadingData = false;
      state.features = action.payload;
    },
    setFeaturesCount(state, action) {
      state.isLoadingData = false;
      state.featureCount = action.payload;
    },
    setLoading(state) {
      state.isLoadingCreate = true;
    },
    clearLoading(state) {
      state.isLoadingCreate = false;
    },
    setIsFeatureCreated(state, action) {
      state.isFeatureCreated = true;
      state.isLoadingCreate = false;
    },
    clearIsFeatureCreated(state, action) {
      state.isFeatureCreated = false;
    },
    setFeature(state, action) {
      state.isLoadingData = false;
      state.feature = action.payload;
    },
    deleteFeature(state, action) {
      state.features = state.features.filter(
        (item) => item._id !== action.payload
      );
    },
  },
});

const featureReducer = featureSlice.reducer;
const featureActions = featureSlice.actions;

export { featureReducer, featureActions };
