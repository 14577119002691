import baseRequest from "../../utils/baseUrl";
import { toast } from "react-toastify";
import { serviceActions } from "../slices/servicesSlice";

export const getAllServices = () => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get("/services");
      dispatch(serviceActions.setServices(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getServicesCount = () => {
  return async (dispatch, getState) => {
    try {
      const data = await baseRequest.get("/services/count", {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(serviceActions.setServicesCount(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getServiceById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get(`/services/${id}`);
      dispatch(serviceActions.setService(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const createService = (service) => {
  return async (dispatch, getState) => {
    try {
      dispatch(serviceActions.setLoading());
      await baseRequest.post("/services", service, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(serviceActions.setIsServiceCreated());
      setTimeout(() => dispatch(serviceActions.clearIsServiceCreated()), 2000);
      toast.success("Service Created Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch(serviceActions.clearLoading());
    }
  };
};

export const updateServiceImage = (image, id) => {
  return async (dispatch, getState) => {
    try {
      await baseRequest.put(`/services/update-image/${id}`, image, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Image Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const updateService = (service, id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.put(`/services/${id}`, service, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(serviceActions.setService(data));
      toast.success("Service Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const deleteService = (id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.delete(`/services/${id}`, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(serviceActions.deleteService(data.id));
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};
