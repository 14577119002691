import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const UpdataProject = ({
  submitUpdateImageForm,
  submitUpdateForm,
  backLink,
  data,
}) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", direction: "ltr" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          component={"form"}
          onSubmit={submitUpdateImageForm}
        >
          <img src={data?.image?.url} width={"200"} alt="" />
          <TextField type="file" id="image" name="image" />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="success"
            sx={{ mt: 2 }}
          >
            Update Image
          </Button>
        </Box>
      </Box>
      <Divider variant="fullWidth" sx={{ my: 3 }} />
      <Box>
        <Grid
          component={"form"}
          onSubmit={submitUpdateForm}
          container
          sx={{ my: 3, mx: "auto", maxWidth: "85%" }}
          spacing={3}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title_en"
              name="title_en"
              label="Title EN"
              fullWidth
              defaultValue={data?.title_en}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title_ar"
              name="title_ar"
              label="Title AR"
              defaultValue={data?.title_ar}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="description_en"
              name="description_en"
              label="Description EN"
              defaultValue={data?.description_en}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="description_ar"
              name="description_ar"
              label="Description AR"
              defaultValue={data?.description_ar}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="projectType"
              name="projectType"
              label="Project Type"
              fullWidth
              defaultValue={data?.projectType}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="videoLink"
              name="videoLink"
              label="Video Link"
              fullWidth
              defaultValue={data?.videoLink}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="projectLink"
              name="projectLink"
              label="Project Link"
              fullWidth
              defaultValue={data?.projectLink}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="features"
              name="features"
              label="Features"
              defaultValue={data?.features}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="technologies"
              name="technologies"
              label="Technologies"
              defaultValue={data?.technologies}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <Link to={`/supervisor/${backLink}`}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mb: 3 }}
              >
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UpdataProject;
