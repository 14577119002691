import { Grid } from "@mui/material";
import React from "react";

const StaticsCard = ({ title, description }) => {
  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className="shadow-lg text-center py-5 px-10 rounded-xl hover:shadow-lg hover:shadow-purple-300 transition-all">
        <p className="text-4xl font-medium my-5 text-purple-700">{title}</p>
        <p className="text-purple-400 text-lg my-5">{description}</p>
      </div>
    </Grid>
  );
};

export default StaticsCard;
