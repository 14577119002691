import React, { useEffect } from "react";
import {
  createService,
  deleteService,
  getAllServices,
} from "../../../redux/services/servicesAPI";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Box, Button, Container } from "@mui/material";
import AdminSidebar from "../AdminSidebar";
import AdminCreateCard from "../AdminCreateCard";
import Loader from "../../../components/Loader";
import Swal from "sweetalert2";
import { serviceActions } from "../../../redux/slices/servicesSlice";
import AdminTable from "../AdminTable";
import ReplayIcon from "@mui/icons-material/Replay";

const AdminServices = () => {
  const service = useSelector((state) => state.services);
  const { isLoadingCreate } = useSelector((state) => state.services);
  const dispatch = useDispatch();

  const submitCreateForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(
      createService({
        title_ar: formData.get("title_ar"),
        title_en: formData.get("title_en"),
        description_ar: formData.get("description_ar"),
        description_en: formData.get("description_en"),
        image: formData.get("image"),
      })
    );
    dispatch(getAllServices());
  };

  const deleteDialog = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteService(id));
        dispatch(serviceActions.deleteService(id));
        Swal.fire("Deleted!", "Service has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getAllServices());
  }, [dispatch]);
  return (
    <Box sx={{ display: "flex", direction: "ltr" }}>
      <AdminSidebar />
      <Container>
        <AdminCreateCard
          title={"Create New Service"}
          submitCreateForm={submitCreateForm}
          isLoadingCreate={isLoadingCreate}
        />

        {service.isLoadingData ? (
          <Loader />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{
                  cursor: "pointer",
                  color: "purple",
                  fontWeight: "700",
                  textAlign: "end",
                }}
                onClick={() => dispatch(getAllServices())}
              >
                <ReplayIcon />
              </Button>
            </Box>
            <AdminTable
              data={service.services}
              deleteDialog={deleteDialog}
              detailsLink="services"
            />
          </>
        )}
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default AdminServices;
