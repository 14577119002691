import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    isLoadingData: true,
    isProjectCreated: false,
    isLoadingCreate: false,
    isError: false,
    projects: [],
    project: null,
    projectCount: 0,
  },

  reducers: {
    setProjects(state, action) {
      state.isLoadingData = false;
      state.projects = action.payload;
    },
    setProjectsCount(state, action) {
      state.isLoadingData = false;
      state.projectCount = action.payload;
    },
    setLoading(state) {
      state.isLoadingCreate = true;
    },
    clearLoading(state) {
      state.isLoadingCreate = false;
    },
    setIsProjectCreated(state, action) {
      state.isProjectCreated = true;
      state.isLoadingCreate = false;
    },
    clearIsProjectCreated(state, action) {
      state.isProjectCreated = false;
    },
    setProject(state, action) {
      state.isLoadingData = false;
      state.project = action.payload;
    },
    deleteProject(state, action) {
      state.projects = state.projects.filter(
        (item) => item._id !== action.payload
      );
    },
  },
});

const projectReducer = projectSlice.reducer;
const projectActions = projectSlice.actions;

export { projectReducer, projectActions };
