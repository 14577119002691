import { createSlice } from "@reduxjs/toolkit";

const technologySlice = createSlice({
  name: "technology",
  initialState: {
    isLoadingData: true,
    isTechnologyCreated: false,
    isLoadingCreate: false,
    isError: false,
    technologies: [],
    technology: null,
    technologiesCount: 0,
  },

  reducers: {
    setTechnologies(state, action) {
      state.isLoadingData = false;
      state.technologies = action.payload;
    },
    setTechnologiesCount(state, action) {
      state.isLoadingData = false;
      state.technologiesCount = action.payload;
    },
    setLoading(state) {
      state.isLoadingCreate = true;
    },
    clearLoading(state) {
      state.isLoadingCreate = false;
    },
    setIsTechnologyCreated(state, action) {
      state.isTechnologyCreated = true;
      state.isLoadingCreate = false;
    },
    clearIsTechnologyCreated(state, action) {
      state.isTechnologyCreated = false;
    },
    setTechnology(state, action) {
      state.isLoadingData = false;
      state.technology = action.payload;
    },
    deleteTechnology(state, action) {
      state.technologies = state.technologies.filter(
        (item) => item._id !== action.payload
      );
    },
  },
});

const technologyReducer = technologySlice.reducer;
const technologyActions = technologySlice.actions;

export { technologyReducer, technologyActions };
