import { Box, Button, Container } from "@mui/material";
import React, { useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  createTechnology,
  deleteTechnology,
  getAllTechnologies,
} from "../../../redux/services/technologyApi";
import AdminCreateCard from "../AdminCreateCard";
import Loader from "../../../components/Loader";
import AdminTable from "../AdminTable";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { technologyActions } from "../../../redux/slices/technologySlice";
import ReplayIcon from "@mui/icons-material/Replay";

const AdminTechnologies = () => {
  const dispatch = useDispatch();
  const technology = useSelector((state) => state.technology);
  const { isLoadingCreate } = useSelector((state) => state.technology);

  const submitCreateForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(
      createTechnology({
        title_ar: formData.get("title_ar"),
        title_en: formData.get("title_en"),
        description_ar: formData.get("description_ar"),
        description_en: formData.get("description_en"),
        image: formData.get("image"),
      })
    );
    dispatch(getAllTechnologies());
  };

  const deleteDialog = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTechnology(id));
        dispatch(technologyActions.deleteTechnology(id));
        Swal.fire("Deleted!", "Technology has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getAllTechnologies());
  }, [dispatch]);
  return (
    <Box sx={{ display: "flex" }}>
      <AdminSidebar />
      <Container>
        <AdminCreateCard
          title={"Create New Technology"}
          submitCreateForm={submitCreateForm}
          isLoadingCreate={isLoadingCreate}
        />

        {technology.isLoadingData ? (
          <Loader />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{
                  cursor: "pointer",
                  color: "purple",
                  fontWeight: "700",
                  textAlign: "end",
                }}
                onClick={() => dispatch(getAllTechnologies())}
              >
                <ReplayIcon />
              </Button>
            </Box>
            <AdminTable
              data={technology.technologies}
              deleteDialog={deleteDialog}
              detailsLink="technologies"
            />
          </>
        )}
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default AdminTechnologies;
