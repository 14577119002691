import { configureStore } from "@reduxjs/toolkit";
import { serviceReducer } from "./slices/servicesSlice";
import { authReducer } from "./slices/authSlice";
import { featureReducer } from "./slices/featuresSlice";
import { technologyReducer } from "./slices/technologySlice";
import { projectReducer } from "./slices/projectsSlice";
import { messageReducer } from "./slices/messagesSlice";
import { blogReducer } from "./slices/blogSlice";

const store = configureStore({
  reducer: {
    services: serviceReducer,
    features: featureReducer,
    auth: authReducer,
    technology: technologyReducer,
    project: projectReducer,
    message: messageReducer,
    blog: blogReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
