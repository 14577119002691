import React, { useEffect } from "react";
import AboutBanner from "../components/AboutBanner";
import AboutSecondSection from "../components/AboutSecondSection";
import TechnologiesSection from "../components/TechnologiesSection";
import GetInTouchSection from "../components/GetInTouchSection";
import ServicesSection from "../components/ServicesSection";
import FeaturesSection from "../components/FeaturesSection";
import PartnersSection from "../components/PartnersSection";
import { getAllTechnologies } from "../redux/services/technologyApi";
import { useDispatch, useSelector } from "react-redux";
import { getAllServices } from "../redux/services/servicesAPI";
import { getAllFeatures } from "../redux/services/featuresApi";
import Loader from "../components/Loader";

const AboutScreen = () => {
  const dispatch = useDispatch();
  const technology = useSelector((state) => state.technology);
  const service = useSelector((state) => state.services);
  const feature = useSelector((state) => state.features);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getAllServices());
    dispatch(getAllFeatures());
    dispatch(getAllTechnologies());
  }, [dispatch]);
  return (
    <div>
      {service.isLoadingData ||
      feature.isLoadingData ||
      technology.isLoadingData ? (
        <Loader />
      ) : (
        <>
          <AboutBanner />
          <AboutSecondSection />
          <TechnologiesSection technology={technology} />
          <GetInTouchSection />
          <ServicesSection service={service} />
          <FeaturesSection feature={feature} />
          <PartnersSection />
        </>
      )}
    </div>
  );
};

export default AboutScreen;
