import baseRequest from "../../utils/baseUrl";
import { toast } from "react-toastify";
import { technologyActions } from "../slices/technologySlice";

export const getAllTechnologies = () => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get("/tech");
      dispatch(technologyActions.setTechnologies(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getTechnologiesCount = () => {
  return async (dispatch, getState) => {
    try {
      const data = await baseRequest.get("/tech/count", {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(technologyActions.setTechnologiesCount(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getTechnologyById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get(`/tech/${id}`);
      dispatch(technologyActions.setTechnology(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const createTechnology = (technology) => {
  return async (dispatch, getState) => {
    try {
      dispatch(technologyActions.setLoading());
      await baseRequest.post("/tech", technology, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(technologyActions.setIsTechnologyCreated());
      setTimeout(
        () => dispatch(technologyActions.clearIsTechnologyCreated()),
        2000
      );
      toast.success("Technology Created Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch(technologyActions.clearLoading());
    }
  };
};

export const updateTechnologyImage = (image, id) => {
  return async (dispatch, getState) => {
    try {
      await baseRequest.put(`/tech/update-image/${id}`, image, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Image Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const updateTechnology = (technology, id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.put(`/tech/${id}`, technology, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(technologyActions.setTechnology(data));
      toast.success("Technology Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const deleteTechnology = (id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.delete(`/tech/${id}`, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(technologyActions.deleteTechnology(data.id));
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};
