import "./App.css";
import Navbar from "./components/Navbar";
import { Routes, Route, Navigate } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import ContactScreen from "./screens/ContactScreen";
import ContainerLayout from "./layouts/ContainerLayout";
import Footer from "./components/Footer";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import AdminDashboard from "./screens/admin/AdminDashboard";
import AdminServices from "./screens/admin/admin-services/AdminServices";
import AdminProjects from "./screens/admin/admin-projects/AdminProjects";
import AdminFeatures from "./screens/admin/admin-features/AdminFeatures";
import AdminTechnologies from "./screens/admin/admin-tech/AdminTechnologies";
import AdminMessages from "./screens/admin/admin-messages/AdminMessages";
import LoginScreen from "./screens/LoginScreen";
import { useSelector } from "react-redux";
import AdminServiceDetails from "./screens/admin/admin-services/AdminServiceDetails";
import AdminFeaturesDetails from "./screens/admin/admin-features/AdminFeaturesDetails";
import AdminTechnologyDetails from "./screens/admin/admin-tech/AdminTechnologyDetails";
import AdminProjectDetails from "./screens/admin/admin-projects/AdminProjectDetails";
import i18n from "./i18n";
import { useEffect } from "react";
import AdminBlogs from "./screens/admin/admin-blog/AdminBlogs";
import AdminBlogDetails from "./screens/admin/admin-blog/AdminBlogDetails";
import BlogScreen from "./screens/BlogScreen";
import BlogDetailsScreen from "./screens/BlogDetailsScreen";

function App() {
  const lang = localStorage.getItem("lang");
  const i18nextLng = localStorage.getItem("i18nextLng");
  const language = i18n.language;
  document.body.dir = i18n.dir();
  useEffect(() => {
    if (language === "en") {
      document.body.dir = i18n.dir("ltr");
    } else {
      document.body.dir = i18n.dir("rtl");
    }
  }, [language]);

  const { user } = useSelector((state) => state.auth);
  return (
    <div
      className="App"
      dir={lang === "en" || i18nextLng === "en" ? "ltr" : "rtl"}
    >
      {!user && <Navbar />}
      <ContainerLayout>
        <Routes>
          {/* <Route path="*" re element={<Navigate replace to={"/"} />} /> */}
          <Route path="/" element={<HomeScreen />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
          <Route path="/blog" element={<BlogScreen />} />
          <Route path="/blog/:id" element={<BlogDetailsScreen />} />
          <Route
            path="/login-supervisor"
            element={!user ? <LoginScreen /> : <Navigate to={"/supervisor"} />}
          />
          {/*Admin*/}
          <Route path="/supervisor">
            <Route
              index
              element={user ? <AdminDashboard /> : <Navigate to={"/"} />}
            />
            <Route
              path="services"
              element={user ? <AdminServices /> : <Navigate to={"/"} />}
            />
            <Route
              path="services/:id"
              element={user ? <AdminServiceDetails /> : <Navigate to={"/"} />}
            />
            <Route
              path="projects"
              element={user ? <AdminProjects /> : <Navigate to={"/"} />}
            />
            <Route
              path="projects/:id"
              element={user ? <AdminProjectDetails /> : <Navigate to={"/"} />}
            />
            <Route
              path="features"
              element={user ? <AdminFeatures /> : <Navigate to={"/"} />}
            />
            <Route
              path="features/:id"
              element={user ? <AdminFeaturesDetails /> : <Navigate to={"/"} />}
            />
            <Route
              path="technologies"
              element={user ? <AdminTechnologies /> : <Navigate to={"/"} />}
            />
            <Route
              path="technologies/:id"
              element={
                user ? <AdminTechnologyDetails /> : <Navigate to={"/"} />
              }
            />
            <Route
              path="messages"
              element={user ? <AdminMessages /> : <Navigate to={"/"} />}
            />
            <Route
              path="blog"
              element={user ? <AdminBlogs /> : <Navigate to={"/"} />}
            />
            <Route
              path="blog/:id"
              element={user ? <AdminBlogDetails /> : <Navigate to={"/"} />}
            />
          </Route>
        </Routes>
      </ContainerLayout>
      {!user && <Footer />}
    </div>
  );
}

export default App;
