import React, { useEffect } from "react";
import { TextField, Button, Typography, Box, Paper } from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createMessage } from "../redux/services/messagesApi";
import { Link } from "react-router-dom";

const ContactForm = () => {
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const { isLoadingCreate } = useSelector((state) => state.message);

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const messageData = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone_number: formData.get("phone_number"),
      message: formData.get("message"),
    };
    dispatch(createMessage(messageData));
  };

  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  return (
    <div
      className="flex flex-wrap justify-center items-center"
      data-aos="fade-right"
    >
      <div className="md:max-w-[500px] sm:max-w-[600px] shadow-md border border-gray-200 m-auto rounded-3xl md:p-8 sm:p-2">
        <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
          <p className="text-center md:text-3xl sm:text-2xl font-bold md:mb-4 sm:mb-1 text-purple-900">
            {t("contact.getTouchTitle")}
          </p>
          <hr className="md:w-[5%] sm:w-[10%] m-auto border-t-4 mb-8 border-purple-800" />
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label={t("contact.formName")}
              // onChange={(e) => setName(e.target.value)}
              margin="normal"
              required
              style={{ direction: lang === "en" ? "ltr" : "rtl" }}
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label={t("contact.formEmail")}
              // onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              required
              // variant="standard"
              bgcolor="#6B21A8"
              type="email"
            />
            <TextField
              fullWidth
              id="phone_number"
              name="phone_number"
              label={t("contact.formPhone")}
              // onChange={(e) => setPhone_number(e.target.value)}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              id="message"
              name="message"
              label={t("contact.formMessage")}
              // onChange={(e) => setMessage(e.target.value)}
              margin="normal"
              required
              multiline
              rows={4}
            />
            <Button
              color="secondary"
              variant="outlined"
              type="submit"
              sx={{ mt: 2, p: 1.2, borderRadius: 4 }}
              fullWidth="true"
              disabled={isLoadingCreate}
            >
              {t("contact.formSubmit")}
            </Button>
          </form>
        </Box>
      </div>
      <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
        {/* <Link to={"tel:00905375094610"}>
          <Paper
            variant="outlined"
            sx={{
              px: 2,
              py: 2,
              my: 4,
              display: "flex",
              alignItems: "center",
              borderRadius: 8,
              boxShadow: 5,
              bgcolor: "#6B21A8",
              color: "white",
              minWidth: "400px",
              textAlign: "center",
              direction: "ltr",
            }}
          >
            <PhoneInTalkIcon sx={{ fontSize: "40px", mx: 2 }} />
            <Typography sx={{ fontSize: 22, fontWeight: "700" }}>
              +90 537 509 46 10
            </Typography>
          </Paper>
        </Link> */}
        <Link to={"mailto:hello@rokispace.com"}>
          <Paper
            variant="outlined"
            sx={{
              px: 2,
              py: 2,
              my: 4,
              display: "flex",
              alignItems: "center",
              borderRadius: 8,
              boxShadow: 5,
              bgcolor: "#6B21A8",
              color: "white",
              minWidth: "400px",
              textAlign: "center",
              direction: "ltr",
            }}
          >
            <ForwardToInboxIcon sx={{ fontSize: "40px", mx: 2 }} />
            <Typography sx={{ fontSize: 22, fontWeight: "700" }}>
              hello@rokispace.com
            </Typography>
          </Paper>
        </Link>
      </Box>
    </div>
  );
};

export default ContactForm;
