import { Box, Button, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import CreatePostModal from "./CreatePostModal";
import { useDispatch, useSelector } from "react-redux";
import {
  createPost,
  deletePost,
  getAllPosts,
} from "../../../redux/services/blogApi";
import ReplayIcon from "@mui/icons-material/Replay";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader";
import Swal from "sweetalert2";
import { blogActions } from "../../../redux/slices/blogSlice";
import BlogTable from "./BlogTable";

const AdminBlogs = () => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.blog);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [descriptionAr, setDescriptionAr] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  console.log(posts);
  const submitCreateForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(
      createPost({
        title_ar: formData.get("title_ar"),
        title_en: formData.get("title_en"),
        description_ar: descriptionAr,
        description_en: descriptionEn,
        image: formData.get("image"),
      })
    );
    dispatch(getAllPosts());
  };

  const deleteDialog = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePost(id));
        dispatch(blogActions.deletePost(id));
        Swal.fire("Deleted!", "Post has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getAllPosts());
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex", direction: "ltr" }}>
      <AdminSidebar />
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{ my: 3 }}
            size="large"
            onClick={handleOpen}
            // disabled={isLoadingCreate}
          >
            Create Post
          </Button>
          <Button
            sx={{ cursor: "pointer", color: "purple", fontWeight: "700" }}
            onClick={() => dispatch(getAllPosts())}
          >
            <ReplayIcon />
          </Button>
        </Box>
        {posts.isLoadingData ? (
          <Loader />
        ) : (
          <BlogTable
            data={posts.posts}
            deleteDialog={deleteDialog}
            detailsLink="blog"
          />
        )}
      </Container>
      <CreatePostModal
        open={open}
        handleClose={handleClose}
        isLoadingCreate={""}
        submitCreateForm={submitCreateForm}
        descriptionAr={descriptionAr}
        setDescriptionAr={setDescriptionAr}
        descriptionEn={descriptionEn}
        setDescriptionEn={setDescriptionEn}
      />
      <ToastContainer />
    </Box>
  );
};

export default AdminBlogs;
