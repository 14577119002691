import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import TranslateIcon from "@mui/icons-material/Translate";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Container, ListItem, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 240;

function Navbar(props) {
  const { t, i18n } = useTranslation();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleChangeLang = (lang) => {
    document.location.reload();
    i18n.changeLanguage(lang);
    i18n.dir(lang === "ar" ? "rtl" : "ltr");
    localStorage.setItem("lang", lang);
  };

  const navbarData = [
    { text: t("header.home"), link: "/" },
    { text: t("header.about"), link: "/about" },
    { text: t("header.blog"), link: "/blog" },
    { text: t("header.contact"), link: "/contact" },
  ];
  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography sx={{ my: 2, textAlign: "right", p: 2 }}>
        <CloseIcon onClick={handleDrawerToggle} />
      </Typography>
      <Divider />
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to={"/"}>
          <img
            src="imgs/rokispace-banner.png"
            style={{ margin: "auto" }}
            width={"200px"}
            alt=""
          />
        </Link>
      </Typography>
      <Divider />
      <List>
        {navbarData.map((item, index) => (
          <ListItem
            onClick={handleDrawerToggle}
            key={index}
            sx={{ color: "#6B21A8", fontWeight: "700" }}
          >
            <Link className="px-1" to={item.link}>
              {item.text}
            </Link>
          </ListItem>
        ))}
        <ListItem
          sx={{
            color: "#6B21A8",

            px: 0,
          }}
        >
          <Button
            onClick={() => handleChangeLang("ar")}
            sx={{ fontFamily: "Almarai", fontSize: "14px", fontWeight: "700" }}
            color="secondary"
          >
            <img
              src="/imgs/saudi-arabia.png"
              alt="ksa-flag"
              className="mx-2"
              width={24}
            />
            العربية
          </Button>
        </ListItem>
        <ListItem
          sx={{
            color: "#6B21A8",

            fontSize: "14px",
            px: 0,
          }}
        >
          <Button
            onClick={() => handleChangeLang("en")}
            sx={{ fontFamily: "Cairo", fontWeight: "700" }}
            color="secondary"
          >
            <img
              src="/imgs/united-states.png"
              alt="usa-flag"
              className="mx-2"
              width={24}
            />
            English
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <AppBar
        component="nav"
        // style={{ position: "sticky" }}
        color="inherit"
      >
        <Container>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Link to={"/"}>
                <img src="imgs/rokispace-banner.png" width={"200px"} alt="" />
              </Link>
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navbarData.map((item, index) => (
                <Button
                  key={index}
                  sx={{ color: "#6B21A8", fontWeight: "700", margin: "0 8px" }}
                >
                  <Link to={item.link}>{item.text}</Link>
                </Button>
              ))}
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                color="secondary"
              >
                <TranslateIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => handleChangeLang("en")}
                  sx={{ fontFamily: "Cairo" }}
                >
                  <img
                    src="/imgs/united-states.png"
                    alt="usa-flag"
                    className="mx-2"
                    width={24}
                  />
                  English
                </MenuItem>
                <MenuItem
                  onClick={() => handleChangeLang("ar")}
                  sx={{ fontFamily: "Almarai" }}
                >
                  <img
                    src="/imgs/saudi-arabia.png"
                    alt="ksa-flag"
                    className="mx-2"
                    width={24}
                  />
                  العربية
                </MenuItem>
              </Menu>
            </Box>
            {user ? (
              <p className="text-white bg-purple-600 px-3 py-1 rounded-xl">
                Hi!, {user.userName} <WavingHandIcon />
              </p>
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="persistent"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;
