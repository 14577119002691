import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { ThreeDots } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,

  overflow: "scroll",
  height: "90%",
  overflowX: "hidden",
};

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockqoute"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockqoute",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
];

const CreatePostModal = ({
  open,
  handleClose,
  submitCreateForm,
  isLoadingCreate,
  descriptionAr,
  setDescriptionAr,
  descriptionEn,
  setDescriptionEn,
}) => {
  return (
    <Modal
      sx={{ direction: "ltr" }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" fontWeight="600" color="purple">
          Add New Post
        </Typography>
        <Grid
          component={"form"}
          id="formData"
          onSubmit={submitCreateForm}
          container
          sx={{ my: 3, mx: "auto", maxWidth: "85%" }}
          spacing={3}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title_ar"
              name="title_ar"
              label="Title AR"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title_en"
              name="title_en"
              label="Title EN"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ minHeight: "400px" }}>
            <Typography>Description Arabic</Typography>
            <ReactQuill
              id="description_ar"
              name="description_ar"
              theme="snow"
              value={descriptionAr}
              onChange={setDescriptionAr}
              modules={modules}
              formats={formats}
              style={{ height: "300px" }}
              // className="min-h-[400px]"
            />
          </Grid>

          <Grid item xs={12} sm={12} sx={{ minHeight: "400px" }}>
            <Typography>Description English</Typography>
            <ReactQuill
              id="description_en"
              name="description_en"
              theme="snow"
              value={descriptionEn}
              onChange={setDescriptionEn}
              modules={modules}
              formats={formats}
              style={{ height: "300px", mb: 5 }}
              // className="min-h-[400px]"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="image"
              name="image"
              label="Image"
              type="file"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoadingCreate}
            >
              {isLoadingCreate ? (
                <ThreeDots height="20" width="20" color="#7B1FA2" />
              ) : (
                "Create"
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CreatePostModal;
