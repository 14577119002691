import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const BlogTable = ({ data, deleteDialog, detailsLink }) => {
  return (
    <TableContainer component={Paper} sx={{ p: 3, direction: "ltr" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell align="center">Title EN</TableCell>
            <TableCell align="center">Title AR</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow
              key={item._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <img
                  src={item.image.url}
                  className="rounded-full w-[50px] h-[50px] border-purple-700 border-2"
                  alt=""
                />
              </TableCell>
              <TableCell align="center">{item.title_en}</TableCell>
              <TableCell align="center">{item.title_ar}</TableCell>
              <TableCell align="center">
                {new Date(item.createdAt).toLocaleString()}
              </TableCell>
              <TableCell align="center">
                <Link to={`/supervisor/${detailsLink}/${item._id}`}>
                  <EditIcon color="success" />
                </Link>
              </TableCell>
              <TableCell align="center">
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  color="error"
                  onClick={() => deleteDialog(item._id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlogTable;
