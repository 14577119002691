import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThreeDots } from "react-loader-spinner";

const AdminCreateCard = ({ title, submitCreateForm, isLoadingCreate }) => {
  return (
    <Accordion sx={{ mb: 5, direction: "ltr" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" color={"purple"}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          component={"form"}
          id="formData"
          onSubmit={submitCreateForm}
          container
          sx={{ my: 3, mx: "auto", maxWidth: "85%" }}
          spacing={3}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title_ar"
              name="title_ar"
              label="Title AR"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title_en"
              name="title_en"
              label="Title EN"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="description_ar"
              name="description_ar"
              label="Description AR"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="description_en"
              name="description_en"
              label="Description EN"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="image"
              name="image"
              label="Image"
              type="file"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoadingCreate}
            >
              {isLoadingCreate ? (
                <ThreeDots height="20" width="20" color="#7B1FA2" />
              ) : (
                "Create"
              )}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdminCreateCard;
