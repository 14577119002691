import baseRequest from "../../utils/baseUrl";
import { toast } from "react-toastify";
import { projectActions } from "../slices/projectsSlice";

export const getAllProjects = () => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get("/projects");
      dispatch(projectActions.setProjects(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getProjectsCount = () => {
  return async (dispatch, getState) => {
    try {
      const data = await baseRequest.get("/projects/count", {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(projectActions.setProjectsCount(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getProjectById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get(`/projects/${id}`);
      dispatch(projectActions.setProject(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const createProject = (project) => {
  return async (dispatch, getState) => {
    try {
      dispatch(projectActions.setLoading());
      await baseRequest.post("/projects", project, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(projectActions.setIsProjectCreated());
      setTimeout(() => dispatch(projectActions.clearIsProjectCreated()), 2000);
      toast.success("Project Created Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch(projectActions.clearLoading());
    }
  };
};

export const updateProjectImage = (image, id) => {
  return async (dispatch, getState) => {
    try {
      await baseRequest.put(`/projects/update-image/${id}`, image, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Image Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const updateProject = (project, id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.put(`/projects/${id}`, project, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(projectActions.setProject(data));
      toast.success("Project Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const deleteProject = (id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.delete(`/projects/${id}`, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(projectActions.deleteProject(data.id));
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};
