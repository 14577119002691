import React from "react";

const ContainerLayout = ({ children }) => {
  return (
    <div className="md:px-20 md:py-10 sm:px-6 sm:py-10 md:my-12 sm:my-8 min-h-[100vh] max-w-[100vw] overflow-hidden">
      {children}
    </div>
  );
};

export default ContainerLayout;
