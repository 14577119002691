import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getTechnologyById,
  updateTechnology,
  updateTechnologyImage,
} from "../../../redux/services/technologyApi";
import AdminUpdateCard from "../AdminUpdateCard";
import Loader from "../../../components/Loader";
import { Box, Card, Container } from "@mui/material";
import { ToastContainer } from "react-toastify";
import AdminSidebar from "../AdminSidebar";

const AdminTechnologyDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { technology, isLoadingData } = useSelector(
    (state) => state.technology
  );

  const submitUpdateForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(
      updateTechnology(
        {
          title_ar: formData.get("title_ar"),
          title_en: formData.get("title_en"),
          description_ar: formData.get("description_ar"),
          description_en: formData.get("description_en"),
        },
        id
      )
    );
  };

  const submitUpdateImageForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(updateTechnologyImage({ image: formData.get("image") }, id));
  };
  useEffect(() => {
    dispatch(getTechnologyById(id));
  }, [id, dispatch]);

  return (
    <Box sx={{ display: "flex" }}>
      <AdminSidebar />
      <Container>
        <Card sx={{ mt: 5, borderRadius: 5, p: 5 }}>
          {isLoadingData ? (
            <Loader />
          ) : (
            <AdminUpdateCard
              backLink={"technologies"}
              submitUpdateForm={submitUpdateForm}
              submitUpdateImageForm={submitUpdateImageForm}
              data={technology}
            />
          )}
        </Card>
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default AdminTechnologyDetails;
