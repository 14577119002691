import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "../assets/web-animation4.json";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const AboutBanner = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  return (
    <div>
      <section className="px-3 py-5 lg:py-5" data-aos="fade-in">
        <div className="grid lg:grid-cols-2 items-center justify-items-center gap-5">
          <div className="order-2 lg:order-1 flex flex-col justify-center md:items-start sm:items-center">
            <p className="md:text-4xl sm:text-2xl capitalize font-bold text-purple-900 md:text-start sm:text-center">
              {t("aboutUs.bannerTitle")}
            </p>
            <p className="mt-2 text-sm md:text-lg text-gray-500 md:text-start mb-5 sm:text-center">
              {t("aboutUs.bannerDesc")}
            </p>
            <Button color="inherit" size="large" variant="outlined">
              <Link to={"/contact"}>{t("aboutUs.bannerBtn")}</Link>
            </Button>
          </div>
          <div className="order-1 lg:order-2">
            {/* <img className=" object-cover w-[100%]" src="imgs/banner1.png" alt="" /> */}
            <Lottie height={800} width={800} animationData={animationData} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutBanner;
