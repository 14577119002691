import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import StaticsCard from "../../components/StaticsCard";
import AdminSidebar from "./AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getServicesCount } from "../../redux/services/servicesAPI";
import Loader from "../../components/Loader";
import { getProjectsCount } from "../../redux/services/projectsApi";
import { getFeaturesCount } from "../../redux/services/featuresApi";
import { getTechnologiesCount } from "../../redux/services/technologyApi";
import { getMessagesCount } from "../../redux/services/messagesApi";
import { getPostsCount } from "../../redux/services/blogApi";
import WavingHandIcon from "@mui/icons-material/WavingHand";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);
  const projects = useSelector((state) => state.project);
  const features = useSelector((state) => state.features);
  const technologies = useSelector((state) => state.technology);
  const messages = useSelector((state) => state.message);
  const blog = useSelector((state) => state.blog);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getServicesCount());
    dispatch(getProjectsCount());
    dispatch(getFeaturesCount());
    dispatch(getTechnologiesCount());
    dispatch(getMessagesCount());
    dispatch(getPostsCount());
  }, [dispatch]);
  return (
    <div dir="ltr">
      <Box sx={{ display: "flex", direction: "ltr" }}>
        <AdminSidebar />

        {services.isLoadingDate ||
        projects.isLoadingDate ||
        features.isLoadingDate ||
        technologies.isLoadingDate ||
        blog.isLoadingDate ||
        messages.isLoadingDate ? (
          <Loader />
        ) : (
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item sm={12}>
              <Typography variant="h4" fontWeight={500} color="purple">
                Hi, Admin! <WavingHandIcon fontSize={"42px"} />
              </Typography>
            </Grid>
            <StaticsCard
              title={services?.servicesCount?.data}
              description={"Services"}
            />
            <StaticsCard
              title={projects?.projectCount?.data}
              description={"Projects"}
            />
            <StaticsCard
              title={features?.featureCount?.data}
              description={"Features"}
            />
            <StaticsCard
              title={technologies?.technologiesCount?.data}
              description={"Technologies"}
            />
            <StaticsCard
              title={messages?.messageCount?.data}
              description={"Messages"}
            />
            <StaticsCard title={blog?.postsCount?.data} description={"Posts"} />
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default AdminDashboard;
