import { createSlice } from "@reduxjs/toolkit";

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    isLoadingData: true,
    isPostCreated: false,
    isLoadingCreate: false,
    isError: false,
    posts: [],
    post: null,
    postsCount: 0,
  },

  reducers: {
    setPosts(state, action) {
      state.isLoadingData = false;
      state.posts = action.payload;
    },
    setPostsCount(state, action) {
      state.isLoadingData = false;
      state.postsCount = action.payload;
    },
    setLoading(state) {
      state.isLoadingCreate = true;
    },
    clearLoading(state) {
      state.isLoadingCreate = false;
    },
    setIsPostCreated(state, action) {
      state.isPostCreated = true;
      state.isLoadingCreate = false;
    },
    clearIsPostCreated(state, action) {
      state.isPostCreated = false;
    },
    setPost(state, action) {
      state.isLoadingData = false;
      state.post = action.payload;
    },
    deletePost(state, action) {
      state.posts = state.posts.filter((item) => item._id !== action.payload);
    },
  },
});

const blogReducer = blogSlice.reducer;
const blogActions = blogSlice.actions;

export { blogReducer, blogActions };
