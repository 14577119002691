import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { ThreeDots } from "react-loader-spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,

  overflow: "scroll",
  height: "90%",
  overflowX: "hidden",
};

const CreateProjectModal = ({
  open,
  handleClose,
  submitCreateForm,
  isLoadingCreate,
}) => {
  return (
    <Modal
      sx={{ direction: "ltr" }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" fontWeight="600" color="purple">
          Add New Project
        </Typography>
        <Grid
          component={"form"}
          id="formData"
          onSubmit={submitCreateForm}
          container
          sx={{ my: 3, mx: "auto", maxWidth: "85%" }}
          spacing={3}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title_ar"
              name="title_ar"
              label="Title AR"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title_en"
              name="title_en"
              label="Title EN"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="description_ar"
              name="description_ar"
              label="Description AR"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="description_en"
              name="description_en"
              label="Description EN"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="videoLink"
              name="videoLink"
              label="Video Link"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="projectLink"
              name="projectLink"
              label="Project Link"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="projectType"
              name="projectType"
              label="Project Type"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="features"
              name="features"
              label="Features"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="technologies"
              name="technologies"
              label="Technologies"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="image"
              name="image"
              label="Image"
              type="file"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoadingCreate}
            >
              {isLoadingCreate ? (
                <ThreeDots height="20" width="20" color="#7B1FA2" />
              ) : (
                "Create"
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CreateProjectModal;
