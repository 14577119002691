import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import GridCard from "./GridCard";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const AboutSecondSection = () => {
  const { t } = useTranslation();
  const cardsData = [
    {
      title: t("aboutUs.planningTitle"),
      description: t("aboutUs.planningDesc"),
      img: "/imgs/1.png",
    },
    {
      title: t("aboutUs.designTitle"),
      description: t("aboutUs.designDesc"),
      img: "/imgs/2.png",
    },
    {
      title: t("aboutUs.testingTitle"),
      description: t("aboutUs.testingDesc"),
      img: "/imgs/3.png",
    },
    {
      title: t("aboutUs.deploymentTitle"),
      description: t("aboutUs.deploymentDesc"),
      img: "/imgs/4.png",
    },
  ];
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  return (
    <div className="my-20 bg-transparent1" data-aos="fade-right">
      <p className="text-center md:text-4xl sm:text-2xl font-bold md:mb-4 sm:mb-1 text-purple-900">
        {t("aboutUs.whatWeOffer")}
      </p>
      <hr className="md:w-[5%] sm:w-[10%] m-auto border-t-4 mb-8 border-purple-800" />
      <Grid container spacing={5} position="relative" zIndex="999">
        {cardsData.map((item, index) => (
          <GridCard
            key={index}
            className="md:min-h-[600px] sm:min-h-[200px]"
            imageSrc={item.img}
            title={item.title}
            description={item.description}
          />
        ))}
      </Grid>
    </div>
  );
};

export default AboutSecondSection;
