import baseRequest from "../../utils/baseUrl";
import { toast } from "react-toastify";
import { messageActions } from "../slices/messagesSlice";

export const getAllMessages = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.get("/messages", {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(messageActions.setMessages(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getMessagesCount = () => {
  return async (dispatch, getState) => {
    try {
      const data = await baseRequest.get("/messages/count", {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(messageActions.setMessagesCount(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getMessageById = (id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.get(`/messages/${id}`, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(messageActions.setMessage(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const createMessage = (message) => {
  return async (dispatch, getState) => {
    try {
      dispatch(messageActions.setLoading());
      await baseRequest.post("/messages", message);
      dispatch(messageActions.setIsMessageCreated());
      setTimeout(() => dispatch(messageActions.clearIsMessageCreated()), 2000);
      toast.success("Thank you, your message was sent successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch(messageActions.clearLoading());
    }
  };
};
