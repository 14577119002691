import { Box, Button, Container } from "@mui/material";
import React, { useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  createFeature,
  deleteFeature,
  getAllFeatures,
} from "../../../redux/services/featuresApi";
import { featureActions } from "../../../redux/slices/featuresSlice";
import Swal from "sweetalert2";
import AdminCreateCard from "../AdminCreateCard";
import AdminTable from "../AdminTable";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader";
import ReplayIcon from "@mui/icons-material/Replay";

const AdminFeatures = () => {
  const dispatch = useDispatch();
  const features = useSelector((state) => state.features);
  const { isLoadingCreate } = useSelector((state) => state.features);

  const submitCreateForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(
      createFeature({
        title_ar: formData.get("title_ar"),
        title_en: formData.get("title_en"),
        description_ar: formData.get("description_ar"),
        description_en: formData.get("description_en"),
        image: formData.get("image"),
      })
    );
    dispatch(getAllFeatures());
  };

  const deleteDialog = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFeature(id));
        dispatch(featureActions.deleteFeature(id));
        Swal.fire("Deleted!", "Feature has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getAllFeatures());
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex", direction: "ltr" }}>
      <AdminSidebar />
      <Container>
        <AdminCreateCard
          title={"Create New Feature"}
          submitCreateForm={submitCreateForm}
          isLoadingCreate={isLoadingCreate}
        />

        {features.isLoadingData ? (
          <Loader />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{
                  cursor: "pointer",
                  color: "purple",
                  fontWeight: "700",
                  textAlign: "end",
                }}
                onClick={() => dispatch(getAllFeatures())}
              >
                <ReplayIcon />
              </Button>
            </Box>
            <AdminTable
              data={features.features}
              deleteDialog={deleteDialog}
              detailsLink="features"
            />
          </>
        )}
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default AdminFeatures;
