import { Box, Button, Container } from "@mui/material";
import { useEffect, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  createProject,
  deleteProject,
  getAllProjects,
} from "../../../redux/services/projectsApi";
import Swal from "sweetalert2";
import { projectActions } from "../../../redux/slices/projectsSlice";
import { ToastContainer } from "react-toastify";
import CreateProjectModal from "./CreateProjectModal";
import Loader from "../../../components/Loader";
import AdminTable from "../AdminTable";
import ReplayIcon from "@mui/icons-material/Replay";

const AdminProjects = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const { isLoadingCreate } = useSelector((state) => state.project);

  const submitCreateForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const featuresArray = formData
      .get("features")
      .split(",")
      .map((item) => item.trim());
    const technologiesArray = formData
      .get("technologies")
      .split(",")
      .map((item) => item.trim());
    dispatch(
      createProject({
        title_ar: formData.get("title_ar"),
        title_en: formData.get("title_en"),
        description_ar: formData.get("description_ar"),
        description_en: formData.get("description_en"),
        videoLink: formData.get("videoLink"),
        projectLink: formData.get("projectLink"),
        projectType: formData.get("projectType"),

        features: featuresArray,
        technologies: technologiesArray,
        image: formData.get("image"),
      })
    );
    dispatch(getAllProjects());
  };

  const deleteDialog = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProject(id));
        dispatch(projectActions.deleteProject(id));
        Swal.fire("Deleted!", "Project has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getAllProjects());
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex", direction: "ltr" }}>
      <AdminSidebar />
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{ my: 3 }}
            size="large"
            onClick={handleOpen}
          >
            Create Project
          </Button>
          <Button
            sx={{ cursor: "pointer", color: "purple", fontWeight: "700" }}
            onClick={() => dispatch(getAllProjects())}
          >
            <ReplayIcon />
          </Button>
        </Box>
        {project.isLoadingData ? (
          <Loader />
        ) : (
          <AdminTable
            data={project.projects}
            deleteDialog={deleteDialog}
            detailsLink="projects"
          />
        )}
      </Container>
      <CreateProjectModal
        open={open}
        handleClose={handleClose}
        isLoadingCreate={isLoadingCreate}
        submitCreateForm={submitCreateForm}
      />
      <ToastContainer />
    </Box>
  );
};

export default AdminProjects;
