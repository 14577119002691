import React, { useEffect } from "react";
import HeroSection from "../components/HeroSection";
import ServicesSection from "../components/ServicesSection";
import FeaturesSection from "../components/FeaturesSection";
import TechnologiesSection from "../components/TechnologiesSection";
import { getAllServices } from "../redux/services/servicesAPI";
import { useDispatch, useSelector } from "react-redux";
import GetInTouchSection from "../components/GetInTouchSection";
import { getAllFeatures } from "../redux/services/featuresApi";
import { getAllTechnologies } from "../redux/services/technologyApi";
import Loader from "../components/Loader";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const service = useSelector((state) => state.services);
  const feature = useSelector((state) => state.features);
  const technology = useSelector((state) => state.technology);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getAllServices());
    dispatch(getAllFeatures());
    dispatch(getAllTechnologies());
  }, [dispatch]);

  return (
    <div>
      {service.isLoadingData ||
      feature.isLoadingData ||
      technology.isLoadingData ? (
        <Loader />
      ) : (
        <>
          <HeroSection />
          <ServicesSection service={service} />
          <GetInTouchSection />
          <FeaturesSection feature={feature} />
          <TechnologiesSection technology={technology} />
        </>
      )}
    </div>
  );
};

export default HomeScreen;
