import { createSlice } from "@reduxjs/toolkit";

const messageSlice = createSlice({
  name: "message",
  initialState: {
    isLoadingData: true,
    isMessageCreated: false,
    isLoadingCreate: false,
    isError: false,
    messages: [],
    message: null,
    messageCount: 0,
  },

  reducers: {
    setMessages(state, action) {
      state.isLoadingData = false;
      state.messages = action.payload;
    },
    setMessagesCount(state, action) {
      state.isLoadingData = false;
      state.messageCount = action.payload;
    },
    setLoading(state) {
      state.isLoadingCreate = true;
    },
    clearLoading(state) {
      state.isLoadingCreate = false;
    },
    setIsMessageCreated(state, action) {
      state.isMessageCreated = true;
      state.isLoadingCreate = false;
    },
    clearIsMessageCreated(state, action) {
      state.isMessageCreated = false;
    },
    setMessage(state, action) {
      state.isLoadingData = false;
      state.message = action.payload;
    },
  },
});

const messageReducer = messageSlice.reducer;
const messageActions = messageSlice.actions;

export { messageReducer, messageActions };
