import { Grid } from "@mui/material";
import React from "react";

const GridCard = ({ title, description, imageSrc, className }) => {
  return (
    <Grid item lg={3} md={6} xs={12}>
      <div
        className={`${className} shadow-sm border cursor-default border-gray-200 text-center md:py-5 md:px-8 sm:px-4 sm:py-3  rounded-xl hover:shadow-md hover:shadow-purple-100 transition-all `}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            width={"50px"}
            height={"50px"}
            className="mx-auto my-3"
            alt=""
          />
        ) : null}
        <p className="text-xl font-bold my-5 text-purple-900">{title}</p>
        <p className="text-gray-500 my-5">{description}</p>
      </div>
    </Grid>
  );
};

export default GridCard;
