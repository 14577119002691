import baseRequest from "../../utils/baseUrl";
import { toast } from "react-toastify";
import { blogActions } from "../slices/blogSlice";

export const getAllPosts = () => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get("/blog");
      dispatch(blogActions.setPosts(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getPostsCount = () => {
  return async (dispatch, getState) => {
    try {
      const data = await baseRequest.get("/blog/count", {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(blogActions.setPostsCount(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const getPostById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await baseRequest.get(`/blog/${id}`);
      dispatch(blogActions.setPost(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const createPost = (post) => {
  return async (dispatch, getState) => {
    try {
      dispatch(blogActions.setLoading());
      await baseRequest.post("/blog", post, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(blogActions.setIsPostCreated());
      setTimeout(() => dispatch(blogActions.clearIsPostCreated()), 2000);
      toast.success("Post Created Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch(blogActions.clearLoading());
    }
  };
};

export const updateBlogImage = (image, id) => {
  return async (dispatch, getState) => {
    try {
      await baseRequest.put(`/blog/update-image/${id}`, image, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Image Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const updatePost = (post, id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.put(`/blog/${id}`, post, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(blogActions.setPost(data));
      toast.success("Post Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const deletePost = (id) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await baseRequest.delete(`/blog/${id}`, {
        headers: {
          Authorization: "Bearer " + getState().auth.user.token,
        },
      });
      dispatch(blogActions.deletePost(data.id));
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};
