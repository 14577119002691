import React, { useEffect, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getPostById,
  updateBlogImage,
  updatePost,
} from "../../../redux/services/blogApi";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockqoute"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockqoute",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
];

const AdminBlogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { post, isLoadingData } = useSelector((state) => state.blog);
  const [descriptionAr, setDescriptionAr] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");

  const submitUpdateForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(
      updatePost(
        {
          title_ar: formData.get("title_ar"),
          title_en: formData.get("title_en"),
          description_ar: descriptionAr,
          description_en: descriptionEn,
        },
        id
      )
    );
  };

  const submitUpdateImageForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(updateBlogImage({ image: formData.get("image") }, id));
  };

  useEffect(() => {
    dispatch(getPostById(id));
  }, [dispatch, id]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", direction: "ltr" }}>
        <AdminSidebar />
        {isLoadingData ? (
          <Loader />
        ) : (
          <Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              component={"form"}
              onSubmit={submitUpdateImageForm}
            >
              <img src={post?.image?.url} width={"200"} alt="" />
              <TextField type="file" id="image" name="image" />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                sx={{ mt: 2 }}
              >
                Update Image
              </Button>
            </Box>
            <Divider variant="fullWidth" sx={{ my: 3 }} />
            <Box>
              <Grid
                component={"form"}
                onSubmit={submitUpdateForm}
                container
                sx={{ my: 3, mx: "auto", maxWidth: "85%" }}
                spacing={3}
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="title_en"
                    name="title_en"
                    label="Title EN"
                    fullWidth
                    defaultValue={post?.title_en}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="title_ar"
                    name="title_ar"
                    label="Title AR"
                    defaultValue={post?.title_ar}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ minHeight: "400px" }}>
                  <Typography>Description English</Typography>
                  <ReactQuill
                    id="description_en"
                    name="description_en"
                    theme="snow"
                    defaultValue={post?.description_en}
                    onChange={setDescriptionEn}
                    modules={modules}
                    formats={formats}
                    style={{ height: "300px", mb: 5 }}
                    // className="min-h-[400px]"
                  />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ minHeight: "400px" }}>
                  <Typography>Description Arabic</Typography>
                  <ReactQuill
                    id="description_ar"
                    name="description_ar"
                    theme="snow"
                    defaultValue={post?.description_ar}
                    //   value={descriptionAr}
                    onChange={setDescriptionAr}
                    modules={modules}
                    formats={formats}
                    style={{ height: "300px" }}
                    // className="min-h-[400px]"
                  />
                </Grid>

                <Grid item md={12} xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="success"
                    sx={{ mt: 2 }}
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  <Link to={`/supervisor/blog`}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ mb: 3 }}
                    >
                      Back
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Container>
        )}
      </Box>

      <ToastContainer />
    </>
  );
};

export default AdminBlogDetails;
