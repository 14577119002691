import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllMessages } from "../../../redux/services/messagesApi";
import Loader from "../../../components/Loader";

const AdminMessages = () => {
  const dispatch = useDispatch();
  const { messages, isLoadingData } = useSelector((state) => state.message);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getAllMessages());
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex", direction: "ltr" }}>
      <AdminSidebar />
      <Container>
        {isLoadingData ? (
          <Loader />
        ) : (
          <>
            <Typography variant="h4" my={3} color="purple">
              All Messages
            </Typography>
            <TableContainer component={Paper} sx={{ p: 3, my: 5 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Phone Number</TableCell>
                    <TableCell align="center">Email</TableCell>{" "}
                    <TableCell align="center">Message</TableCell>
                    <TableCell align="center">Data & Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messages.map((item) => (
                    <TableRow
                      key={item._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">{item.phone_number}</TableCell>
                      <TableCell align="center">{item.email}</TableCell>
                      <TableCell align="center">{item.message}</TableCell>
                      <TableCell align="center">
                        {new Date(item.createdAt).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </Box>
  );
};

export default AdminMessages;
