import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const yearDate = new Date().getFullYear();
  return (
    <section className="bg-purple-950">
      <div className="max-w-screen-xl px-4 py-5 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        <img
          src="imgs/rokispace-banner-white.png"
          className="w-[300px] m-auto"
          alt=""
        />
        <nav className="flex flex-wrap justify-center -mx-5 -my-2">
          <div className="px-5 py-2">
            <Link
              to={"/contact"}
              className="text-base leading-6 text-gray-200 hover:text-gray-400 transition-all"
            >
              {t("footer.contact")}
            </Link>
          </div>
          {/* <div className="px-5 py-2">
            <Link
              to={"#"}
              className="text-base leading-6 text-gray-200 hover:text-gray-400 transition-all"
            >
              Terms
            </Link>
          </div> */}
          <div className="px-5 py-2">
            <Link
              to={"/privacy-policy"}
              className="text-base leading-6 text-gray-200 hover:text-gray-400 transition-all"
            >
              {t("footer.privacy")}
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to={"/blog"}
              className="text-base leading-6 text-gray-200 hover:text-gray-400 transition-all"
            >
              {t("header.blog")}
            </Link>
          </div>
          {/* <div className="px-5 py-2">
            <Link
              to={"#"}
              className="text-base leading-6 text-gray-200 hover:text-gray-400 transition-all"
            >
              {t("footer.siteMap")}
            </Link>
          </div> */}
        </nav>
        <div className="flex justify-center mt-8 space-x-6" dir="ltr">
          {/* <a
            href="wa.me/"
            target="_blank"
            className="text-gray-400 hover:text-gray-200 transition-all"
          >
            <WhatsAppIcon />
          </a> */}
          <a
            href="https://www.linkedin.com/company/rokispace/"
            target="_blank"
            className="text-gray-400 hover:text-gray-200 transition-all"
          >
            <LinkedInIcon />
          </a>
          {/* <Link
            to={"#"}
            target="_blank"
            className="text-gray-400 hover:text-gray-200 transition-all"
          >
            <InstagramIcon />
          </Link> */}
        </div>
        <p
          className="mt-8 md:text-base sm:text-[14px] leading-6 text-center text-gray-300 bg-slate-100 bg-opacity-10 p-2 rounded-xl"
          dir="ltr"
        >
          © {yearDate} {t("footer.copyRights")}
        </p>
      </div>
    </section>
  );
};

export default Footer;
