import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import GridCard from "./GridCard";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const FeaturesSection = ({ feature }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  return (
    <div className="my-20" data-aos="fade-right">
      <p className="text-center text-4xl maxMobile:text-2xl font-bold md:mb-4 sm:mb-1 text-purple-900">
        {t("home.whyChooseUs")}
      </p>
      <hr className="md:w-[5%] sm:w-[10%] m-auto border-t-4 mb-8 border-purple-800" />
      <Grid container spacing={2}>
        {feature.features.map((item) => (
          <GridCard
            className={`
             min-h-[380px] maxMobile:!min-h-[280px]`}
            key={item?._id}
            imageSrc={item?.image?.url}
            title={lang === "en" ? item?.title_en : item?.title_ar}
            description={
              lang === "en" ? item?.description_en : item?.description_ar
            }
          />
        ))}
      </Grid>
    </div>
  );
};

export default FeaturesSection;
