import { Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicyScreen = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="my-20">
      <p className="text-center text-4xl font-semibold md:mb-4 sm:mb-1 text-purple-900">
        Privacy Policy
      </p>
      <hr className="md:w-[5%] sm:w-[10%] m-auto border-t-4 mb-8 border-purple-800" />
      <Paper
        sx={{ p: 5, borderRadius: 5, boxShadow: 4, width: "80vw", m: "auto" }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, color: "#6B21A8", mt: 4, mb: 2 }}
        >
          {t("privacy.title1")}
        </Typography>
        <Typography>{t("privacy.desc1")}</Typography>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, color: "#6B21A8", mt: 4, mb: 2 }}
        >
          {t("privacy.title2")}
        </Typography>
        <Typography>{t("privacy.desc2")}</Typography>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, color: "#6B21A8", mt: 4, mb: 2 }}
        >
          {t("privacy.title3")}
        </Typography>
        <Typography>{t("privacy.desc3")}</Typography>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, color: "#6B21A8", mt: 4, mb: 2 }}
        >
          {t("privacy.title4")}
        </Typography>
        <Typography>{t("privacy.desc4")}</Typography>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, color: "#6B21A8", mt: 4, mb: 2 }}
        >
          {t("privacy.title5")}
        </Typography>
        <Typography>{t("privacy.desc5")}</Typography>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, color: "#6B21A8", mt: 4, mb: 2 }}
        >
          {t("privacy.title6")}
        </Typography>
        <Typography>{t("privacy.desc6")}</Typography>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, color: "#6B21A8", mt: 4, mb: 2 }}
        >
          {t("privacy.title7")}
        </Typography>
        <Typography>
          {t("privacy.desc7")} <strong>hello@rokisoft.com.</strong>
        </Typography>
      </Paper>
    </div>
  );
};

export default PrivacyPolicyScreen;
