import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPosts } from "../redux/services/blogApi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";

const BlogScreen = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.blog);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getAllPosts());
  }, [dispatch]);
  return (
    <>
      {posts.isLoadingData ? (
        <Loader />
      ) : (
        <Box>
          <Typography
            variant="h4"
            fontWeight={700}
            sx={{
              my: 5,
              color: "purple",
            }}
          >
            {t("blog.latestPosts")}
          </Typography>
          <Grid container spacing={2} position="relative" zIndex="999">
            {posts.posts.map((item) => (
              <Grid key={item._id} item lg={3} md={6} xs={12}>
                <Link to={`/blog/${item._id}`}>
                  <Card
                    key={item._id}
                    sx={{
                      borderRadius: 8,
                      textAlign: "center",
                      border: "1px solid rgb(229 231 235)",
                      boxShadow: "0px 0px 8px 0px rgba(224,215,224,0.47)",
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: "100%",
                        height: 250,
                        backgroundSize: "cover",
                        objectFit: "cover",
                      }}
                      image={item.image.url}
                      title="blog image"
                    />
                    <Box>
                      <CardContent sx={{ p: 2 }}>
                        <Typography
                          gutterBottom
                          variant="h4"
                          color={"purple"}
                          fontWeight={"700"}
                          fontSize={20}
                          lineHeight={1.4}
                          component="div"
                          sx={{ minHeight: "110px", mt: 3 }}
                        >
                          {lang === "en" ? item?.title_en : item?.title_ar}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default BlogScreen;
