import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getProjectById,
  updateProject,
  updateProjectImage,
} from "../../../redux/services/projectsApi";
import { Box, Card, Container } from "@mui/material";
import AdminSidebar from "../AdminSidebar";
import Loader from "../../../components/Loader";
import { ToastContainer } from "react-toastify";
import UpdataProject from "./UpdataProject";

const AdminProjectDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { project, isLoadingData } = useSelector((state) => state.project);

  const submitUpdateForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(
      updateProject(
        {
          title_ar: formData.get("title_ar"),
          title_en: formData.get("title_en"),
          description_ar: formData.get("description_ar"),
          description_en: formData.get("description_en"),
        },
        id
      )
    );
  };

  const submitUpdateImageForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(updateProjectImage({ image: formData.get("image") }, id));
  };
  useEffect(() => {
    dispatch(getProjectById(id));
  }, [dispatch, id]);
  return (
    <Box sx={{ display: "flex", direction: "ltr" }}>
      <AdminSidebar />
      <Container>
        <Card sx={{ mt: 5, borderRadius: 5, p: 5 }}>
          {isLoadingData ? (
            <Loader />
          ) : (
            <UpdataProject
              backLink={"projects"}
              submitUpdateForm={submitUpdateForm}
              submitUpdateImageForm={submitUpdateImageForm}
              data={project}
            />
          )}
        </Card>
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default AdminProjectDetails;
